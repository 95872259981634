exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".text-white___2gWul {\n  color: #fff; }\n\n.bg-white___1QI8Y {\n  background-color: #fff; }\n\n.text-light-blue___3NAZs {\n  color: #83d5ff; }\n\n.bg-light-blue___3zmfo {\n  background-color: #83d5ff; }\n\n.text-navy___B0-bg {\n  color: #173963; }\n\n.bg-navy___2AKun {\n  background-color: #173963; }\n\n.text-light-navy___Vdy6c {\n  color: #17396357; }\n\n.bg-light-navy___IZD9V {\n  background-color: #17396357; }\n\n.text-gray___36wTq {\n  color: #a7a7a7; }\n\n.bg-gray___1Gxz2 {\n  background-color: #a7a7a7; }\n\n.text-black___3gXJR {\n  color: #222; }\n\n.bg-black___1MASx {\n  background-color: #222; }\n\n/*\nSpacing\n*/\n@-webkit-keyframes fadein___IgKOu {\n  from {\n    opacity: 0; }\n  to {\n    opacity: 1; } }\n@keyframes fadein___IgKOu {\n  from {\n    opacity: 0; }\n  to {\n    opacity: 1; } }\n\n.Hero__logo___1Tygw {\n  top: 50%; }\n\n.Hero__circle___12u24 {\n  position: relative;\n  background: radial-gradient(ellipse at center, white 0%, white 60%, #83d5ff 60%, white 70%);\n  -webkit-transform: rotate(-20deg);\n          transform: rotate(-20deg);\n  max-height: 100vh; }\n  .Hero__circle___12u24::before {\n    display: block;\n    content: \"\";\n    width: 100%;\n    padding-top: 187.5%; }\n  .Hero__circle___12u24 > .inner___1iV2E {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0; }\n  @media (min-width: 832px) {\n    .Hero__circle___12u24 {\n      position: relative;\n      -webkit-transform: rotate(20deg);\n              transform: rotate(20deg); }\n      .Hero__circle___12u24::before {\n        display: block;\n        content: \"\";\n        width: 100%;\n        padding-top: 53.33333%; }\n      .Hero__circle___12u24 > .inner___1iV2E {\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0; } }\n", ""]);

// exports
exports.locals = {
	"text-white": "text-white___2gWul",
	"bg-white": "bg-white___1QI8Y",
	"text-light-blue": "text-light-blue___3NAZs",
	"bg-light-blue": "bg-light-blue___3zmfo",
	"text-navy": "text-navy___B0-bg",
	"bg-navy": "bg-navy___2AKun",
	"text-light-navy": "text-light-navy___Vdy6c",
	"bg-light-navy": "bg-light-navy___IZD9V",
	"text-gray": "text-gray___36wTq",
	"bg-gray": "bg-gray___1Gxz2",
	"text-black": "text-black___3gXJR",
	"bg-black": "bg-black___1MASx",
	"Hero__logo": "Hero__logo___1Tygw",
	"Hero__circle": "Hero__circle___12u24",
	"inner": "inner___1iV2E",
	"fadein": "fadein___IgKOu"
};