exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".text-white___dUM7d {\n  color: #fff; }\n\n.bg-white___1cQRK {\n  background-color: #fff; }\n\n.text-light-blue___1jtaM {\n  color: #83d5ff; }\n\n.bg-light-blue___2c_h3 {\n  background-color: #83d5ff; }\n\n.text-navy___1uhk1 {\n  color: #173963; }\n\n.bg-navy___2rDQh {\n  background-color: #173963; }\n\n.text-light-navy___3xrmy {\n  color: #17396357; }\n\n.bg-light-navy___QlJvg {\n  background-color: #17396357; }\n\n.text-gray___NfMOI {\n  color: #a7a7a7; }\n\n.bg-gray___13i2B {\n  background-color: #a7a7a7; }\n\n.text-black___UIgFD {\n  color: #222; }\n\n.bg-black___3gT0x {\n  background-color: #222; }\n\n/*\nSpacing\n*/\n@-webkit-keyframes fadein___3_Szz {\n  from {\n    opacity: 0; }\n  to {\n    opacity: 1; } }\n@keyframes fadein___3_Szz {\n  from {\n    opacity: 0; }\n  to {\n    opacity: 1; } }\n\n.ScrollToTop__button___1OG5f {\n  bottom: 1rem;\n  right: 1rem;\n  border: 0.005rem solid #a7a7a7;\n  font-size: 1.2rem;\n  -webkit-animation: fadein___3_Szz 3.5s;\n          animation: fadein___3_Szz 3.5s; }\n", ""]);

// exports
exports.locals = {
	"text-white": "text-white___dUM7d",
	"bg-white": "bg-white___1cQRK",
	"text-light-blue": "text-light-blue___1jtaM",
	"bg-light-blue": "bg-light-blue___2c_h3",
	"text-navy": "text-navy___1uhk1",
	"bg-navy": "bg-navy___2rDQh",
	"text-light-navy": "text-light-navy___3xrmy",
	"bg-light-navy": "bg-light-navy___QlJvg",
	"text-gray": "text-gray___NfMOI",
	"bg-gray": "bg-gray___13i2B",
	"text-black": "text-black___UIgFD",
	"bg-black": "bg-black___3gT0x",
	"ScrollToTop__button": "ScrollToTop__button___1OG5f",
	"fadein": "fadein___3_Szz"
};